<template>
  <el-dialog :title="title" :visible.sync="open" width="100%" :close-on-click-modal="false" append-to-body>
    <el-form :model="formData" ref="vForm" :rules="rules" label-position="left" label-width="80px" size="medium"
             @submit.native.prevent>
      <div class="tab-container">
        <el-tabs type="border-card">
          <el-tab-pane name="添加文章" label="添加文章">
            <el-row>
              <el-col :span="12" class="grid-cell">
                <el-form-item label="鸟瞰图" prop="articleBanner" class="label-center-align">
                  <el-upload
                      class="upload-demo"
                      :http-request="handleUpload"
                      :on-change="handleUpload"
                      :before-upload="beforeUpload"
                      :on-success="handleSuccess"
                      :on-error="handleError"
                      :auto-upload="true"
                      :limit="1"
                      :on-exceed="handleExceed"
                  >
                    <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
                    <div slot="tip" class="el-upload__tip">请选择要上传的文件（仅支持单个文件）</div>
                    <!-- 省略手动上传按钮，因为我们将自动上传 -->
                  </el-upload>
                </el-form-item>

                <el-form-item label="备注" prop="remark" class="label-center-align">
                  <el-input v-model="formData.remark" type="text" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="grid-cell">
                <el-form-item label="标题" prop="articleTitle" class="required label-center-align">
                  <el-input v-model="formData.articleTitle" type="text" clearable></el-input>
                </el-form-item>

                <el-form-item label="类别" prop="categoryId" class="required label-center-align">
                  <el-select v-model="formData.categoryId" class="full-width-input" size="medium" clearable
                             filterable>
                    <el-option v-for="(item, index) in categoryIdOptions"
                               :key="index"
                               :label="item.title"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="置顶" prop="isTopFlag" class="label-center-align">
                  <el-switch v-model="formData.isTopFlag"></el-switch>
                </el-form-item>

                <el-form-item label="热门" prop="isHotFlag" class="label-center-align">
                  <el-switch v-model="formData.isHotFlag"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="文章内容" prop="articleContentStr" class="required label-center-align">
              <Editor v-model="formData.articleContentStr"/>
            </el-form-item>
            <el-row>
              <el-col :span="12" class="grid-cell">
                <div class="static-content-item">
                  <el-button type="primary" @click="submitInsertForm">提交</el-button>
                </div>
              </el-col>
              <el-col :span="12" class="grid-cell">
                <div class="static-content-item">
                  <el-button type="danger">重置</el-button>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane name="编辑文章" label="编辑文章">
            <el-row>
              <el-col :span="12" class="grid-cell">
                <el-form-item label="鸟瞰图" prop="articleBanner" class="label-center-align">
                  <el-upload
                      class="upload-demo"
                      :http-request="handleUpload"
                      :on-change="handleUpload"
                      :before-upload="beforeUpload"
                      :on-success="handleSuccess"
                      :on-error="handleError"
                      :auto-upload="true"
                      :limit="1"
                      :on-exceed="handleExceed"
                  >
                    <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
                    <div slot="tip" class="el-upload__tip">请选择要上传的文件（仅支持单个文件）</div>
                    <!-- 省略手动上传按钮，因为我们将自动上传 -->
                  </el-upload>
                </el-form-item>

                <el-form-item label="备注" prop="remark" class="label-center-align">
                  <el-input v-model="formData.remark" type="text" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" class="grid-cell">
                <el-form-item label="标题" prop="articleTitle" class="required label-center-align">
                  <el-input v-model="formData.articleTitle" type="text" clearable></el-input>
                </el-form-item>

                <el-form-item label="类别" prop="categoryId" class="required label-center-align">
                  <el-select v-model="formData.categoryId" class="full-width-input" size="medium" clearable
                             filterable>
                    <el-option v-for="(item, index) in categoryIdOptions"
                               :key="index"
                               :label="item.title"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="置顶" prop="isTopFlag" class="label-center-align">
                  <el-switch v-model="formData.isTopFlag"></el-switch>
                </el-form-item>

                <el-form-item label="热门" prop="isHotFlag" class="label-center-align">
                  <el-switch v-model="formData.isHotFlag"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="文章内容" prop="articleUpdateContentStr" class="required label-center-align">
              <Editor :article-id="this.formData.articleId" :value="formData.articleUpdateContentStr"
                      v-model="formData.articleUpdateContentStr"/>
            </el-form-item>
            <el-row>
              <el-col :span="12" class="grid-cell">
                <div class="static-content-item">
                  <el-button type="primary" @click="submitUpdateForm">修改</el-button>
                </div>
              </el-col>
              <el-col :span="12" class="grid-cell">
                <div class="static-content-item">
                  <el-button type="danger">重置</el-button>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-form>
  </el-dialog>

</template>

<script>
import Editor from "../components/vueEditor.vue";
import {addArticle, fetchCategory, fetchList, updateArticle, uploadImg} from "../api/index";

export default {
  components: {Editor},
  props: {
    // 弹出层标题
    title: ""
  },
  data() {
    return {
      dialogVisible: false,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      // 是否显示弹出层
      open: false,
      queryParam: {
        articleId: ""
      },
      file: "",
      fileList: [],
      article: {},
      formData: {
        articleId: "",
        articleBanner: null,
        remark: "",
        articleTitle: "",
        categoryId: "",
        isTopFlag: true,
        isHotFlag: false,
        articleContentStr: null,
        articleUpdateContentStr: null
      },
      rules: {
        articleTitle: [{
          required: true,
          message: '字段值不可为空',
        }],
        categoryId: [{
          required: true,
          message: '字段值不可为空',
        }],
        articleContentStr: [{
          required: false,
          message: '字段值不可为空',
        }],
      },
      categoryIdOptions: [],
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    //通过此函数将上面上传的文件添加到vue的data内
    getFile(event) {
      this.file = event.currentTarget.files[0];
    },
    handleUpload(item) {
      // 创建一个 loading 实例并赋值给 loading 变量
      let loading = this.$loading({
        text: "正在上传图床服务器,请耐心等待...", // 设置 loading 文本为 "加载中"
        spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
        background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
        target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
      });
      const formData = new FormData();
      formData.append('file', item.raw);
      formData.append("filePath", "article/article_info/article_info_banners/articleId_" + this.queryParam.articleId + "/");
      uploadImg(formData).then(res => {
        if (res.code === 200) {
          this.$message.success("上传成功花费" + res.respTime / 1000 + "秒")
          this.formData.articleBanner = res.data
          loading.close()
        }
      })
    },
    // 父组件传参，窗口初始化方法，nextTick方法可以添加逻辑，如打开窗口时查询数据填充 https://blog.csdn.net/q283614346/article/details/119565238
    init(articleId) {
      let userInfo = this.$store.getters.userInfo;
      this.formData.createBy = userInfo.nickName;
      this.formData.userId = userInfo.userId;
      this.queryParam.articleId = articleId
      this.formData.articleId = articleId
      this.open = true;
      // 初始化分类列表
      fetchCategory(this.queryParam).then(res => {
        if (res.code === 200) {
          this.categoryIdOptions = res.data
          this.formData.categoryId = this.categoryIdOptions[0].id
        }
      })
      fetchList(this.queryParam).then(res => {
        if (res.code === 200) {
          this.formData.articleTitle = res.data.items[0].title
          this.formData.articleUpdateContentStr = res.data.items[0].content
        }
      })
      // 获取完信息后展开dialog
      this.$nextTick(() => {
        this.open = true;
      });
    },
    submitUpdateForm() {

      // 创建一个 loading 实例并赋值给 loading 变量
      let loading = this.$loading({
        text: "正在生成文件修改履历,请耐心等待...", // 设置 loading 文本为 "加载中"
        spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
        background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
        target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
      });
      this.formData.articleContentStr = this.formData.articleUpdateContentStr
      this.formData.userId = this.$store.getters.userInfo.userId;
      this.$refs['vForm'].validate(valid => {
        if (!valid) {
          this.$message.error("表单校验失败")
        } else {
          console.log(this.formData)
          updateArticle(this.formData).then(res => {
            if (res.code === 200) {
              loading.close()
              this.$message.success("修改成功")
              this.$emit('closeDialog'); // 触发父级初始化事件
              location.reload(); // 刷新页面
            }
          })
        }
      })
    },
    submitInsertForm() {
      this.$refs['vForm'].validate(valid => {
        if (!valid) {
          this.$message.error("表单校验失败")
        } else {
          // articleId置空，数据库自增属性
          this.formData.articleId = "";
          this.formData.userId = this.$store.getters.userInfo.userId;
          addArticle(this.formData).then(res => {
            if (res.code === 200) {
              this.$message.success("创建成功")
              this.$emit('closeDialog'); // 触发父级初始化事件
              location.reload(); // 刷新页面
            }
          })
        }
      })
    },
    resetForm() {
      this.$refs['vForm'].resetFields()
    },
    handleChange(file, fileList) {
      // 监听文件选择的变化
      // 在这里可以添加额外的逻辑，比如限制文件类型、大小等
      // 然后触发自定义的上传方法
      if (this.beforeUpload(file)) {
        this.handleUpload({
          file,
          onSuccess: (response) => {
            // 更新 fileList 或其他状态
            this.fileList = [file]; // 假设上传后只保留当前文件
          },
          onError: (error) => {
            // 处理上传失败的情况
            console.error(error);
          },
          // 其他可能的选项...
        });
      }
    },
    handleError(error, file, fileList) {
      // 上传失败后的处理逻辑
      // ...
    },
    handleSuccess(response, file, fileList) {
      // 上传成功后的回调
      this.$message.success('文件上传成功');
      // 假设服务器返回了文件的信息，可以将其添加到 fileList 中，但这里由于只上传一个文件，并且已经自动添加，所以不需要额外操作
    },
    //上传文件之前
    beforeUpload(file) {
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 50; //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$showMessage('上传文件大小不能超过 50MB!');
          return false;
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
//超出文件个数的回调
    handleExceed() {
      this.$message({
        type: 'warning',
        message: '超出最大上传文件数量的限制！'
      });
      return
    },
  }
}

</script>

<style scoped lang="less">
.el-input-number.full-width-input,
.el-cascader.full-width-input {
  width: 100% !important;
}

.el-form-item--medium {
  .el-radio {
    line-height: 36px !important;
  }

  .el-rate {
    margin-top: 8px;
  }
}

.el-form-item--small {
  .el-radio {
    line-height: 32px !important;
  }

  .el-rate {
    margin-top: 6px;
  }
}

.el-form-item--mini {
  .el-radio {
    line-height: 28px !important;
  }

  .el-rate {
    margin-top: 4px;
  }
}

.clear-fix:before,
.clear-fix:after {
  display: table;
  content: "";
}

.clear-fix:after {
  clear: both;
}

.float-right {
  float: right;
}

</style>

<style scoped lang="less">
.table-container {
  table.table-layout {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    td.table-cell {
      display: table-cell;
      height: 36px;
      border: 1px solid #e1e2e3;
    }
  }
}

.tab-container {
}

.label-left-align ::v-deep .el-form-item__label {
  text-align: left;
}

.label-center-align ::v-deep .el-form-item__label {
  text-align: center;
}

.label-right-align ::v-deep .el-form-item__label {
  text-align: right;
}

.custom-label {
}

.static-content-item {
  min-height: 20px;
  display: flex;
  align-items: center;

  ::v-deep .el-divider--horizontal {
    margin: 0;
  }
}

</style>